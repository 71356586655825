<template>
  <section class="body">
    <section class="banner">
      <div class="summary">
        <h3>智能网联车载单元<strong>T-BOX</strong></h3>
        <ul class="tags">
          <li>全芯国产</li>
          <li>Autosar 架构</li>
          <li>通信安全</li>
        </ul>
      </div>
      <img class="img" src="../../assets/vbd/home/tbox.png" width="1140" height="750" />
    </section>

    <section class="content">
        <div class="item cover first">
          <div class="wrapper">
            <h3>矩阵T-Box智能网联模块</h3>
            <p>执行标准：GB/T32960</p>
          </div>
          <img class="img" src="../../assets/vbd/tbox/r_1_1.png" width="504" height="650" />
        </div>
        <div class="item">
          <h3>全芯国产 ｜ 车规级芯片</h3>
          <img class="img" src="../../assets/vbd/tbox/r_1_2.png" width="236" height="86" />
          <ul class="list">
            <li>芯旺微车规级的MCU</li>
            <li>满足AEC-Q100品质认证要求</li>
            <li>主芯片耐温:-40℃~125℃</li>
            <li>核心器件全部国产</li>
            <li>3C认证/GB/T32960认证</li>
          </ul>
        </div>
        <div class="item">
          <h3><span>高精度定位芯片</span><span>自研定位算法</span></h3>
          <img class="img" src="../../assets/vbd/tbox/r_1_3.png" width="180" height="124" />
          <ul class="list">
            <li>亚米级定位：GPS、BDS、GLONASS组合定位，定位精度≤5m</li>
            <li>1秒定位：通过自研定位算法，实现定位时间≤1S</li>
          </ul>
        </div>

        <div class="item">
          <h3>全功能接口 ｜ 超大内存</h3>
          <img class="img" src="../../assets/vbd/tbox/r_2_2.png" width="240" height="64" />
          <ul class="list">
            <li>芯旺微车规级的MCU</li>
            <li>满足AEC-Q100品质认证要求</li>
            <li>主芯片耐温:-40℃~125℃</li>
            <li>核心器件全部国产</li>
            <li>3C认证/GB/T32960认证</li>
          </ul>
        </div>
        <div class="item">
          <h3>全应用模块封装 ｜ 部署简单</h3>
          <img class="img" src="../../assets/vbd/tbox/r_2_3.png" width="160" height="60" />
          <ul class="list">
            <li>全应用模块封装，深度定义车联网基本组件（如：灯语处理组件、定位处理组件、诊断处理组件等）</li>
            <li>统一调用框架与组件之间的软件接口实现具体场景应用功能的快速部署(如:可根据OEM架构特点完成快速部署）</li>
          </ul>
        </div>

        <div class="item first">
          <div class="wrapper">
            <h3><span>软硬件解耦架构</span><span>软件架构全栈自研</span></h3>
            <img class="img" src="../../assets/vbd/tbox/r_3_1.png" width="216" height="80" />
            <ul class="list">
              <li>遵循AUTOSRA软件架构</li>
              <li>软硬件分层解耦，可实现硬件平台高效迁移</li>
              <li>服务层、应用层解耦，可实现多场景全功能覆盖</li>
              <li>软件、工具链全栈自研</li>
            </ul>
          </div>
        </div>
        <div class="item">
          <h3><span>整车控制器OTA</span><span>整车远程诊断</span></h3>
          <img class="img" src="../../assets/vbd/tbox/r_3_2.png" width="240" height="240" />
          <ul class="list">
            <li>真正的整车FOTA</li>
            <li>弱网通信，0误码率，实现即时车辆状态监控</li>
            <li>即时整车远程诊断</li>
          </ul>
        </div>
        <div class="item">
          <h3>加密芯片 ｜ eSIM芯片</h3>
          <img class="img" src="../../assets/vbd/tbox/r_3_3.png" width="120" height="60" />
          <ul class="list">
            <li>专用硬件加密芯片</li>
            <li>eSIM芯片</li>
            <li>支持国密算法</li>
            <li>支持国际常用密码算法AES、ECDSA、SHA-128/256 等</li>
            <li>硬件和软件双级数据加密</li>
            <li>支持线上线下数据双备份，安全性更高</li>
          </ul>
        </div>
    </section>

    <div class="layout">
      <section class="box">
        <h3>应用场景</h3>
        <div class="cover">
          <img class="img" src="../../assets/vbd/tbox/scene.jpg" width="1440" height="630" />
        </div>
        <div class="scene">
          <div class="left">
            <div class="item">
              <h3>橙仕汽车TBOX优势：</h3>
              <ul class="list">
                <li>车规级芯片，AutoSar架构标准开发</li>
                <li>满足国标法规要求，适配网联监管功能</li>
                <li>组件服务化、接口标准化能快速适配OEM各种电气架构</li>
                <li>超车规级信息加密体系，满足内外网分层加密</li>
                <li>整车全场景应用，提供软硬件整套保姆级“交钥匙”解决方案</li>
              </ul>
            </div>
            <div class="item">
              <h3>矩阵TBOX提供</h3>
              <h4>TBOX 硬件 + 整车+平台全域部署解决方案</h4>
              <img class="img" src="../../assets/vbd/tbox/project.png" width="600" height="290" />
              <p>【案例】数智运通平台</p>
              <p>提供TBOX硬件适配、TSP服务搭建、业务后台搭建、C端应用开发、运营管理平台开发，整套“交钥匙”解决方案</p>
            </div>
          </div>
          <div class="right">
            <h3>产品优势</h3>
            <div class="advantage">
              <div class="item">
                <h4>全芯国产</h4>
                <ul class="list">
                  <li>国内首家（目前唯一）支持全部车规级控制芯片方案量产、装车的厂商</li>
                  <li>供货稳定，杜绝断供风险</li>
                  <li>车规级MCU高温宽更稳定</li>
                </ul>
              </div>
              <div class="item">
                <h4>内置HSM模块</h4>
                <ul class="list">
                  <li>车规级IC加密芯片</li>
                  <li>PKI加密体系支持国密算法</li>
                  <li>验签可达2000+次/秒</li>
                </ul>
              </div>
              <div class="item span2">
                <h4>超车规级安全体系</h4>
                <ul class="list">
                  <li>PKI加密体系 PUF验证机制</li>
                  <li>固件不可逆烧录</li>
                  <li>物理防拆保护机制</li>
                  <li>内外网分层加密体系</li>
                  <li>芯片级数字指纹验证不可伪造</li>
                  <li>针对固件反编译破解进行不可逆烧录</li>
                  <li>物理结构破拆报警</li>
                </ul>
              </div>
              <div class="item">
                <h4>软硬件解耦</h4>
                <ul class="list">
                  <li>通过软硬件架构解耦可以按需灵活实现硬件平台无损迁移</li>
                  <li>支持国产MCU方案和典型MCU方案灵活选择</li>
                </ul>
              </div>
              <div class="item">
                <h4>FOTA加密</h4>
                <ul class="list">
                  <li>整车核心零部件固件更新</li>
                  <li>双向认证加密通信</li>
                  <li>固件包内网多段签名验证</li>
                </ul>
              </div>
            </div>

            <ul class="tags">
              <li>真FOTA</li>
              <li>整车远程控制</li>
              <li>整车远程诊断</li>
              <li>AutoSar架构</li>
              <li>软硬件解耦</li>
              <li>全栈自研车规级控制芯片</li>
              <li>1s定位</li>
              <li>全接口</li>
              <li>顶级加密技术</li>
            </ul>
          </div>
        </div>
      </section>

      <section class="box">
        <h3>成功案例</h3>
        <div class="cases">
          <div class="item">
            <img class="img" src="../../assets/vbd/vcu/pic_1.png" width="1076" height="300" />
            <h4><strong>豪驰量产车型全系安装</strong> 助力豪驰汽车实现车云物联</h4>
            <p v-html="'&nbsp;'"></p>
          </div>
          <div class="item">
            <img class="img" src="../../assets/vbd/tbox/jz_install.jpg" width="670" height="278" />
            <h4><strong>矩阵快递物流定制版全系安装</strong></h4>
            <p>助力中国邮政、顺丰速递、申通快递、韵达快递、京东快递提高城配效率</p>
          </div>
        </div>

        <ul class="partner">
          <li><img src="../../assets/vbd/vcu/logo_1.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_2.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_3.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_4.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_5.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_6.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_7.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_8.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_9.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_10.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_11.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_12.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_13.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_14.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_15.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_16.png" width="158" height="74" /></li>
          <!-- 卡位防错乱 -->
          <li class="empty"></li>
          <li class="empty"></li>
          <li class="empty"></li>
          <li class="empty"></li>
          <li class="empty"></li>
        </ul>

      </section>

      <section class="box">
        <h3>规格参数</h3>

        <div class="params">
          <table cellspacing="2" cellpadding="0">
            <colgroup>
              <col width="20%" />
              <col width="30%" />
              <col width="50%" />
            </colgroup>
            <thead>
              <tr>
                <th class="t1">规格项</th>
                <th class="t2" colspan="2">参数</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="t1" rowspan="4">4G模块</td>
                <td class="t2">通信制式</td>
                <td>LTE-FDD:B1/B3/B5/B8 LTE-TDD:B34/B38/B39/B40/B41 GSM:B3/B8</td>
              </tr>
              <tr>
                <td class="t2">数据传输类型</td>
                <td>支持TCP/UDP/FTP/ HTTP/FILE/MQTT/SSL等等</td>
              </tr>
              <tr>
                <td class="t2" rowspan="2">数据传输速度</td>
                <td>（下行）10Mbps</td>
              </tr>
              <tr>
                <td>（上行）5Mbps</td>
              </tr>
              <tr>
                <td class="t1" rowspan="5">GPS模块</td>
                <td class="t2">定位系统</td>
                <td>G支持GPS/北斗定位/格洛纳斯</td>
              </tr>
              <tr>
                <td class="t2 inverse" rowspan="2">灵敏度</td>
                <td>（捕获）-162 dBm</td>
              </tr>
              <tr>
                <td class="inverse">（跟踪）-157 dBm</td>
              </tr>
              <tr>
                <td class="t2">冷启动</td>
                <td>≤32s</td>
              </tr>
              <tr>
                <td class="t2">热启动</td>
                <td>≤3s</td>
              </tr>
            </tbody>
          </table>
          <table cellspacing="0" cellpadding="0">
            <colgroup>
              <col width="20%" />
              <col width="30%" />
              <col width="50%" />
            </colgroup>
            <thead>
              <tr>
                <th class="t1">规格项</th>
                <th class="t2" colspan="2">参数</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="t1" rowspan="10">物理特性</td>
                <td class="t2">外形尺寸(mm)</td>
                <td>120 mm*103mm*33.4mm</td>
              </tr>
              <tr>
                <td class="t2">重量</td>
                <td>280g</td>
              </tr>
              <tr>
                <td class="t2">flash存储</td>
                <td>128MB</td>
              </tr>
              <tr>
                <td class="t2">备用电源</td>
                <td>1000mAH</td>
              </tr>
              <tr>
                <td class="t2">工作环境温度</td>
                <td>-30℃~70℃</td>
              </tr>
              <tr>
                <td class="t2">储存环境温度</td>
                <td>-40℃~85℃</td>
              </tr>
              <tr>
                <td class="t2">防护等级</td>
                <td>IP54</td>
              </tr>
              <tr>
                <td class="t2">工作电流</td>
                <td>≤100mA</td>
              </tr>
              <tr>
                <td class="t2">休眠电流</td>
                <td>≤5mA</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: "T-BOX",
};
</script>

<style lang="scss" scoped>
.layout {
  max-width: 1480px;
}

.list {
  margin-left: 24px;
  font-size: 20px;
  line-height: 26px;
  list-style: disc outside;

  &.nb {
    color: #ff3f00;
  }

  li {
    padding: 5px 0;
    // text-align: justify;
  }
}

.banner {
  display: flex;
  padding-top: 100px;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 850px;
  text-align: center;
  overflow: hidden;
  color: #fff;
  background-color: #FF3F00;

  .summary {
    font-size: 24px;
    line-height: 28px;
    transform: translateX(-50%);

    h3 {
      font-size: 60px;
      color: #fff;
      font-weight: 300;
      line-height: 64px;

      strong {
        display: block;
        padding-top: 16px;
        font-size: 160px;
        line-height: 188px;
        font-weight: 900;
        font-family: Lantinghei SC, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
      }
    }

    .tags {
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 60px auto 0;

      li {
        margin: 10px;
        padding: 0 18px;
        font-size: 22px;
        line-height: 38px;
        border: 2px solid #fff;
        border-radius: 6px;
      }
    }
  }

  .img {
    position: absolute;
    transform: translate(390px, 76px);
  }
}

.content {
  display: grid;
  margin: 0 auto;
  grid-template-columns: 25% 24% 27%;
  grid-gap: 100px 12%;
  padding: 160px 20px 0px;
  justify-content: space-between;
  max-width: 1480px;

  .item {
    font-size: 20px;
    line-height: 26px;

    &.first {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .wrapper {
        max-width: 330px;
      }
    }

    &.cover {
      grid-row: 1 / span 2;
      
      .img {
        margin: 32px 0 0;
      }
    }

    h3 {
      margin-bottom: 2px;
      font-size: 30px;
      line-height: 40px;

      span {
        white-space: nowrap;
      }
    }

    .img {
      display: block;
      margin: 16px;
    }
  }
}

.box {
  margin: 100px 0;

  h3 {
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 36px;
  }

  .cover {
    .img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.scene {
  display: flex;
  flex-wrap: wrap;
  padding-top: 160px;
  font-size: 20px;
  line-height: 30px;

  .left {
    padding-top: 64px;
    width: 600px;

    .item {
      margin-bottom: 60px;

      h3 {
        margin-bottom: 0;
        font-size: 30px;
        line-height: 40px;
      }

      h4 {
        font-size: 26px;
        line-height: 36px;
      }

      .img {
        display: block;
        margin: 32px 0 12px;
        width: 100%;
        height: auto;
        transform: translateX(-6%);
      }

      .list {
        margin-top: 16px;
      }
    }
  }

  .right {
    margin-left: 2.6%;
    flex: 1;
    min-width: 680px;

    h3 {
      margin-bottom: 24px;
      font-size: 30px;
      line-height: 40px;
    }

    .advantage {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 60px 24px;

      .item {
        h4 {
          margin-bottom: 16px;
          font-size: 30px;
          line-height: 40px;
        }

        &.span2 {
          grid-column-start: 3;
          grid-row: 1 / span 2;
        }
      }
    }

    .tags {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 60px auto 0;
      max-width: 670px;

      li {
        margin: 6px;
        padding: 0 20px;
        font-size: 22px;
        line-height: 48px;
        color: #ff3f00;
        border: 1px solid #e73e0c;
        border-radius: 6px;
      }
    }
  }
}

.cases {
  display: flex;
  padding: 40px 0;
  justify-content: space-between;

  .item {
    display: flex;
    flex: 11;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 20px;
    line-height: 30px;

    h4 {
      font-size: 26px;
      line-height: 36px;
    }

    .img {
      margin-bottom: 40px;
      width: 100%;
      height: auto;
      transform: translateX(-16.728%);
    }

    & + .item {
      flex: 9;

      .img {
        transform: translateX(0);
      }
    }
  }
}

.partner {
  display: flex;
  margin: 20px -10px 0;
  justify-content: space-around;
  flex-wrap: wrap;

  li {
    margin: 12px 10px;
    width: 160px;
    height: 76px;
    border: 1px solid #c8c9ca;

    img {
      display: block;
    }
  }

  .empty {
    margin: 0 10px;
    height: 0;
    border: none;
  }
}

.params {
  display: flex;

  table {
    flex: 1;
    font-size: 20px;
    line-height: 26px;

    & + table {
      margin-left: 2.5%;
    }

    th, td {
      padding: 10px 4%;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;

      &.t1 {
        text-align: center;
        border-left: 0;
      }

      &.t2 {
        padding-left: 6%;
        padding-right: 0;
      }

      &.inverse {
        border-bottom-color: #eeeeef;
      }
    }

    th {
      color: #fff;
      background-color: #e73e0c;
    }

    thead {
      tr {
        th {
          border-bottom: none;
        }
      }
    }

    tbody {
      td {
        word-break: break-all;
      }

      tr:nth-child(odd) {
        td {
          background-color: #eeeeef;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .list {
    margin-left: 24px;
    font-size: 14px;
    line-height: 20px;

    li {
      padding: 3px 0;
      // text-align: justify;
    }
  }

  .banner {
    display: flex;
    height: auto;
    min-height: 100vh;
    padding: 120px 0 60px;
    flex-direction: column-reverse;

    .summary {
      font-size: 12px;
      line-height: 16px;
      transform: none;

      h3 {
        font-size: 30px;
        line-height: 32px;

        strong {
          padding-top: 8px;
          font-size: 72px;
          line-height: 86px;
        }
      }

      .tags {
        margin-top: 0;
        padding: 20px 0 0;

        li {
          margin: 3px;
          padding: 0 6px;
          font-size: 12px;
          line-height: 18px;
          border: 1px solid #fff;
          border-radius: 4px;
        }
      }
    }

    .img {
      position: static;
      transform: none;
      width: 100%;
      height: auto;
    }
  }

  .content {
    display: block;
    padding: 80px 20px 0px;

    .item {
      margin-bottom: 32px;
      font-size: 14px;
      line-height: 20px;

      &:first-child {
        margin-bottom: 60px;
      }

      &.first {
        display: block;

        .wrapper {
          max-width: 100%;
        }
      }

      &.cover {
        .img {
          margin: 16px 0 0;
          width: 100%;
          height: auto;
        }
      }

      h3 {
        margin-bottom: 2px;
        font-size: 22px;
        line-height: 30px;

        span {
          white-space: nowrap;
        }
      }

      .img {
        display: block;
        margin: 12px 8px;
      }
    }
  }

  .box {
    margin: 48px 0;

    h3 {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 26px;
    }

    .cover {
      margin: 0 -10px;
    }
  }

  .scene {
    display: block;
    padding: 80px 10px 10px;
    font-size: 14px;
    line-height: 20px;

    .left {
      padding-top: 0;
      width: 100%;

      .item {
        margin-bottom: 32px;

        h3 {
          margin-bottom: 0;
          font-size: 22px;
          line-height: 30px;
        }

        h4 {
          font-size: 16px;
          line-height: 24px;
        }

        .img {
          margin: 20px auto 12px;
          transform: none;
        }

        .list {
          margin-top: 8px;
        }
      }
    }

    .right {
      margin-left: 0;
      min-width: auto;

      h3 {
        margin-bottom: 24px;
        font-size: 22px;
        line-height: 30px;
      }

      .advantage {
        display: block;

        .item {
          margin-bottom: 32px;

          h4 {
            margin-bottom: 8px;
            font-size: 22px;
            line-height: 30px;
          }
        }
      }

      .tags {
        margin: 30px auto 0;
        max-width: 320px;

        li {
          margin: 3px;
          padding: 0 6px;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  .cases {
    display: block;
    padding: 20px 0;

    .item {
      font-size: 12px;
      line-height: 16px;

      h4 {
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 20px;
      }

      .img {
        margin-bottom: 12px;
        width: 100%;
        height: auto;
        transform: translateX(-5%);
      }

      & + .item {
        margin-top: 24px;

        .img {
          width: 100%;
        }
      }
    }
  }

  .partner {
    display: flex;
    margin: 20px -4px 0;
    justify-content: space-around;
    flex-wrap: wrap;

    li {
      margin: 4px;
      width: 81px;
      height: 39px;

      img {
        width: 79px;
        height: 37px;
      }
    }

    .empty {
      margin: 0 4px;
    }
  }

  .params {
    flex-direction: column;

    table {
      flex: 1;
      font-size: 12px;
      line-height: 16px;

      & + table {
        margin: 0;

        thead {
          display: none;
        }
      }

      th, td {
        padding: 8px 4%;
        border-width: 1px;

        &.t1 {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
</style>